// ** Packages **
import { SVGAttributes } from "react";

// ** CSS **
// import "./style/button.css";

// ** Components **
import Icon from "components/Icon";
import Spinner from "components/Loaders/Spinner";

// ** Types **
import { IconTypes } from "components/Icon/types";

export type ButtonPropsType = {
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
  className?: string;
  icon?: IconTypes;
  smallLoaderWrapperClassName?: string;
  iconClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
} & SVGAttributes<SVGElement>;

const Button = (props: ButtonPropsType) => {
  const {
    type = "button",
    children = <></>,
    className = "",
    smallLoaderWrapperClassName = "",
    iconClassName = "",
    icon,
    loading = false,
    disabled,
    onClick,
  } = props;
  return (
    <button
      type={type}
      className={`btnCarpet ${className} ${disabled ? "disable__Btn" : ""}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <Spinner smallLoaderWrapperClassName={smallLoaderWrapperClassName} />
      )}
      <div className="btnText">{children}</div>
      {icon && <Icon className={iconClassName} name={icon} />}
    </button>
  );
};

export default Button;
