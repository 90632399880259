import Tippy from "@tippyjs/react";

// ** Components **
import {
  Lead,
  Order,
  PlusCircle,
  Product,
  Search,
  Setting,
  Users,
} from "assets/icons";
import Button from "components/Button";
import {
  CheckBoxField,
  EmailField,
  InputField,
  PhoneField,
  RadioButtonField,
  SelectField,
  ToggleButtonField,
} from "components/FormField";
import NumberField from "components/FormField/components/common/NumberField";
import Icon from "components/Icon";
import PageLoader from "components/Loaders/PageLoader";
import Spinner from "components/Loaders/Spinner";
import StaticModal from "../StaticModal";
import StaticTable from "../StaticTable";
import TextAreaField from "components/FormField/components/common/TextAreaField";
import ReportStaticPage from "../Pages/ReportPage/reportPage";

const StaticDashboard = () => {
  return (
    <div className="mainWrapper">
      <aside className="sidebar">
        <div className="logoWrapper">
          <img src="images/header__logo.png" alt="Carpet651" />
        </div>
        <div className="menuWrapper flex flex-wrap content-between min-h-[calc(100vh_-_97px)] max-h-[calc(100vh_-_97px)] overflow-y-auto hideScrollbar pb-[15px]">
          <div className="menuWrapper__sub w-full">
            <div className="menuItem active">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Dashboard</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Lead />
                  </div>
                </div>
                <span className="menuName">Leads</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Users />
                  </div>
                </div>
                <span className="menuName">Customers</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Order />
                  </div>
                </div>
                <span className="menuName">Orders</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Product />
                  </div>
                </div>
                <span className="menuName">Products</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Invoices</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Expenses</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Estimates</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">General Ledger</span>
              </button>
            </div>
          </div>
          <div className="menuWrapper__sub w-full">
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Team</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Team</span>
              </button>
            </div>
            <div className="menuItem">
              <button className="menuLink">
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Setting />
                  </div>
                </div>
                <span className="menuName">Settings</span>
              </button>
            </div>
          </div>
        </div>
      </aside>
      <div className="rightMainWrapper">
        <div className="topHeader">
          <h1 className="title text-textDark font-AcuminPro__Bold text-[20px] shrink-0">
            General Ledger - Account Maintenance
          </h1>
          <div className="topHeaderRight w-full flex items-center justify-end pl-[20px]">
            <button className="btnCarpet primaryBtn iconLeft shrink-0">
              <span className="btnText">Add new</span>
              <div className="iconWrapper">
                <div className="svgWrapper">
                  <PlusCircle />
                </div>
              </div>
            </button>
            <div className="field__wrapper mb-0 ml-[15px] w-[430px]">
              <div className="field__inner__wrapper field__has__icon">
                <input
                  type="text"
                  placeholder="Search"
                  className="input__carpet bg-formElementsBG"
                />
                <div className="iconWrapper">
                  <div className="svgWrapper">
                    <Search />
                  </div>
                </div>
              </div>
            </div>
            <div className="avatar__Img w-[40px] h-[40px] ml-[15px] cursor-pointer">
              <div className="inner__wrapper !rounded-full">
                <div className="img__element">
                  <img src="/images/carpet-img.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carpetMainCN__wrapper">
          {/* static theme guide page start */}
          <div className="">
            <div className="carpetAnimated__logo w-[500px]">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 501.9 140.7"
              >
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="33.8195"
                  y1="-2.7027"
                  x2="35.0268"
                  y2="97.6757"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="1" style={{ stopColor: "#3F4897" }} />
                </linearGradient>
                <path
                  style={{ fill: "url(#SVGID_1_)" }}
                  d="M64.4,43.5c-2.6-4.5-7.5-7.7-14.6-9.4c-1.1-0.3-2.3-0.5-3.6-0.7c-1.2-0.1-2.5-0.2-3.9-0.2
	h-0.8c-2.4,0.1-4.7,0.4-6.9,1c-1.5,0.4-2.8,0.9-4.1,1.5c0-0.1,0-0.3,0-0.4v-4.7c0-2.4,0.2-4.6,0.7-6.4c0.4-1.5,1.3-2.1,3-2.1
	c1.6,0,2.6,0.4,3,1.2c0.5,1,0.8,2.2,0.8,3.6V32h28.6V10.8l-0.3-0.3c-2.3-2.1-5.3-3.9-8.9-5.5C53.8,3.5,50,2.3,46.1,1.5
	c-2.5-0.6-5-1-7.5-1.1c-2.5-0.2-4.8-0.2-6.8,0c-12.8,0.8-21,3.7-24.8,9C3.3,14.4,1.3,20,1.1,26v38.6c-0.4,8.4,1.6,15.7,6.1,21.6
	c4.5,6,13.9,9,27.8,9c15.1,0,24.2-3.1,27.9-9.4c3.6-6.1,5.4-14,5.4-23.6C68.2,54.3,67,48,64.4,43.5z M38.9,68.1c0,2-0.5,3.4-1.4,4.4
	c-0.9,0.9-1.8,1.4-3,1.3c-1,0-2-0.4-2.9-1.4c-0.9-0.9-1.4-2.4-1.4-4.3V59c0-1.8,0.5-3.1,1.4-4c0.9-0.8,1.8-1.2,2.8-1.2h0.2
	c1.1,0,2.1,0.4,2.9,1.3c0.9,0.9,1.3,2.2,1.3,3.9V68.1z"
                />
                <g>
                  <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1="102.484"
                    y1="-2.1303"
                    x2="104.6973"
                    y2="93.9533"
                  >
                    <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                    <stop offset="1" style={{ stopColor: "#3F4897" }} />
                  </linearGradient>
                  <path
                    style={{ fill: "url(#SVGID_2_)" }}
                    d="M137.3,61c0.2,8.7-1.6,16.7-5.3,23.8c-3.7,7-12.3,10.5-25.8,10.5c-0.7,0-1.3,0-2,0
		c0,0,0,0,0,0c-1.3,0-2.8-0.1-4.2-0.2c-1.4-0.1-2.9-0.3-4.3-0.6c-4.4-0.6-8.9-1.9-13.2-3.6c-4.3-1.8-8.1-4-11.2-6.5l-0.4-0.3v-27
		h28.6v9.6c0,1.9,0.2,3.6,0.6,5.2c0.2,0.8,0.7,1.7,3.1,1.8c1.3-0.2,2.9-0.7,3.3-1.7c0.4-1.1,0.6-2.3,0.5-3.6V54.9
		c0-1.5-0.4-2.7-1.1-3.4c-0.8-0.8-1.7-1.3-2.6-1.5l-0.3-0.1h-0.5c-0.9,0-1.6,0.2-2.3,0.7c-0.5,0.4-0.8,1-0.8,1.8v1H71.1V2h64v25.8
		h-27.8v-1c0-2-0.4-3.4-1.1-3.9c-0.4-0.2-1.7-0.8-6.6-0.9v9.1c1.1-0.4,2.4-0.7,3.8-1c2-0.4,4.2-0.6,6.5-0.6c0.9,0,1.8,0,2.8,0.1
		c1,0.1,2,0.2,2.9,0.3c4.9,0.7,9.5,2.6,13.7,5.6c4.3,3.1,6.7,7.9,7.2,14.3c0.1,1.7,0.3,3.5,0.4,5.4C137.3,57.2,137.3,59.1,137.3,61z
		"
                  />
                </g>
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="154.592"
                  y1="-1.3959"
                  x2="155.8387"
                  y2="99.2374"
                >
                  <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
                  <stop offset="1" style={{ stopColor: "#3F4897" }} />
                </linearGradient>
                <path
                  style={{ fill: "url(#SVGID_3_)" }}
                  d="M173.6,75.7v18h-35.9v-18l0.5-0.3c1.2-0.6,1.9-1.3,2.2-2.1c0.3-0.9,0.5-1.8,0.5-2.8V25
	c0-0.3,0-0.6-0.1-0.9l0-0.1c-0.1-0.8-0.3-1.7-0.6-2.4c-0.3-0.6-0.8-1-1.7-1.2l-0.8-0.2V2h32.6v67.7c0,0.4,0,0.9,0,1.5
	c0,0.5,0.1,1,0.3,1.6c0.2,0.5,0.4,1,0.8,1.5c0.3,0.4,0.9,0.8,1.6,1.2L173.6,75.7z"
                />
                <path
                  className="plusIcon"
                  d="M500.3,65.7v9.2h-16.8v16.8h-9.2V74.9h-16.8v-9.2h16.8V49h9.2v16.8H500.3z"
                />
                <path
                  className="letter6"
                  d="M64.4,43.5c-2.6-4.5-7.5-7.7-14.6-9.4c-1.1-0.3-2.3-0.5-3.6-0.7c-1.2-0.1-2.5-0.2-3.9-0.2h-0.8
	c-2.4,0.1-4.7,0.4-6.9,1c-1.5,0.4-2.8,0.9-4.1,1.5c0-0.1,0-0.3,0-0.4v-4.7c0-2.4,0.2-4.6,0.7-6.4c0.4-1.5,1.3-2.1,3-2.1
	c1.6,0,2.6,0.4,3,1.2c0.5,1,0.8,2.2,0.8,3.6V32h28.6V10.8l-0.3-0.3c-2.3-2.1-5.3-3.9-8.9-5.5C53.8,3.5,50,2.3,46.1,1.5
	c-2.5-0.6-5-1-7.5-1.1c-2.5-0.2-4.8-0.2-6.8,0c-12.8,0.8-21,3.7-24.8,9C3.3,14.4,1.3,20,1.1,26v38.6c-0.4,8.4,1.6,15.7,6.1,21.6
	c4.5,6,13.9,9,27.8,9c15.1,0,24.2-3.1,27.9-9.4c3.6-6.1,5.4-14,5.4-23.6C68.2,54.3,67,48,64.4,43.5z M38.9,68.1c0,2-0.5,3.4-1.4,4.4
	c-0.9,0.9-1.8,1.4-3,1.3c-1,0-2-0.4-2.9-1.4c-0.9-0.9-1.4-2.4-1.4-4.3V59c0-1.8,0.5-3.1,1.4-4c0.9-0.8,1.8-1.2,2.8-1.2h0.2
	c1.1,0,2.1,0.4,2.9,1.3c0.9,0.9,1.3,2.2,1.3,3.9V68.1z"
                />
                <g>
                  <path
                    className="letter5"
                    d="M137.3,61c0.2,8.7-1.6,16.7-5.3,23.8c-3.7,7-12.3,10.5-25.8,10.5c-0.7,0-1.3,0-2,0c0,0,0,0,0,0
		c-1.3,0-2.8-0.1-4.2-0.2c-1.4-0.1-2.9-0.3-4.3-0.6c-4.4-0.6-8.9-1.9-13.2-3.6c-4.3-1.8-8.1-4-11.2-6.5l-0.4-0.3v-27h28.6v9.6
		c0,1.9,0.2,3.6,0.6,5.2c0.2,0.8,0.7,1.7,3.1,1.8c1.3-0.2,2.9-0.7,3.3-1.7c0.4-1.1,0.6-2.3,0.5-3.6V54.9c0-1.5-0.4-2.7-1.1-3.4
		c-0.8-0.8-1.7-1.3-2.6-1.5l-0.3-0.1h-0.5c-0.9,0-1.6,0.2-2.3,0.7c-0.5,0.4-0.8,1-0.8,1.8v1H71.1V2h64v25.8h-27.8v-1
		c0-2-0.4-3.4-1.1-3.9c-0.4-0.2-1.7-0.8-6.6-0.9v9.1c1.1-0.4,2.4-0.7,3.8-1c2-0.4,4.2-0.6,6.5-0.6c0.9,0,1.8,0,2.8,0.1
		c1,0.1,2,0.2,2.9,0.3c4.9,0.7,9.5,2.6,13.7,5.6c4.3,3.1,6.7,7.9,7.2,14.3c0.1,1.7,0.3,3.5,0.4,5.4C137.3,57.2,137.3,59.1,137.3,61z
		"
                  />
                </g>
                <path
                  className="letter1"
                  d="M173.6,75.7v18h-35.9v-18l0.5-0.3c1.2-0.6,1.9-1.3,2.2-2.1c0.3-0.9,0.5-1.8,0.5-2.8V25
	c0-0.3,0-0.6-0.1-0.9l0-0.1c-0.1-0.8-0.3-1.7-0.6-2.4c-0.3-0.6-0.8-1-1.7-1.2l-0.8-0.2V2h32.6v67.7c0,0.4,0,0.9,0,1.5
	c0,0.5,0.1,1,0.3,1.6c0.2,0.5,0.4,1,0.8,1.5c0.3,0.4,0.9,0.8,1.6,1.2L173.6,75.7z"
                />
                <path
                  className="letterC"
                  d="M211.4,91.4c-17.5,0-30.7-12.9-30.7-30.1c0-17,13.2-29.9,30.7-29.9c4.8,0,7.7,0.6,10.3,1.1
	c1.2,0.2,2.3,0.5,3.5,0.6c1,0.1,1.8,1,1.8,2V48c0,0.8-0.5,1.5-1.2,1.8c-0.3,0.1-0.5,0.2-0.8,0.2c-0.5,0-1-0.2-1.4-0.6
	c-2.8-2.7-6.7-4.1-11.3-4.1c-9.1,0-16.2,7-16.2,16c0,9.1,7.2,16.2,16.4,16.2c3.4,0,8.2-0.7,11.8-4c0.4-0.4,0.9-0.5,1.4-0.5
	c0.3,0,0.5,0.1,0.8,0.2c0.7,0.3,1.2,1,1.2,1.8v12.5c0,1-0.7,1.8-1.7,2c-1.2,0.2-2.3,0.5-3.4,0.7C219.6,90.8,216.5,91.4,211.4,91.4z"
                />
                <g>
                  <path
                    className="letterCBehind"
                    d="M226.1,73.9v14l-0.4,0.1c-1.2,0.2-2.4,0.5-3.5,0.7c-3,0.7-5.9,1.3-10.8,1.3
		c-16.6,0-29.2-12.3-29.2-28.6c0-16.2,12.6-28.4,29.2-28.4c4.7,0,7.5,0.6,10,1.1c1.2,0.2,2.4,0.5,3.6,0.6l0.4,0.1v14.4l-0.8-0.8
		c-3.1-3-7.4-4.5-12.4-4.5c-9.9,0-17.7,7.7-17.7,17.5c0,9.9,7.8,17.7,17.9,17.7c5.3,0,9.7-1.5,12.8-4.4L226.1,73.9z"
                  />
                </g>
                <g>
                  <path
                    className="letterA"
                    d="M259.1,91.4c-2.9,0-5.3-1-6.9-2.8c-2.2,1.8-5.2,2.8-8.3,2.8c-8.5,0-14.2-5.2-14.2-12.9
		c0-8,6.1-13.7,14.9-13.7c1.8,0,3.7,0.4,5.3,1c-0.4-2.7-2.5-4.2-6-4.2c-3.7,0-5.9,1.3-8,3.2c-0.4,0.3-0.9,0.5-1.3,0.5
		c-0.3,0-0.6-0.1-0.8-0.2c-0.7-0.3-1.2-1-1.2-1.8v-9.6c0-1,0.7-1.8,1.7-2c0.7-0.1,1.5-0.4,2.4-0.7c2.3-0.8,5.4-1.8,10.1-1.8
		c11.4,0,17.4,6.4,17.4,18.6v9.9c0,0.8,0.1,1.3,0.2,1.5c0.4,0,0.6,0,0.7,0c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.6,0.1,0.9,0.2
		c0.7,0.3,1.1,1,1.1,1.8v6.5c0,0.6-0.3,1.3-0.8,1.6C265.5,90.1,263.3,91.4,259.1,91.4z M247.2,75.9c-1.7,0-2.9,0.8-2.9,2.1
		c0,1.3,1.1,2.2,2.9,2.2c1.5,0,2.9-1,2.9-2.2C250.1,77,248.9,75.9,247.2,75.9z"
                  />
                </g>
                <g>
                  <path
                    className="letterABehind"
                    d="M265.8,80.4c-0.2,0.2-0.7,0.3-1.6,0.3c-0.5,0-1.6,0-1.6-3v-9.9c0-11.3-5.3-17-15.9-17
		c-4.5,0-7.4,1-9.6,1.8c-1,0.4-1.9,0.7-2.7,0.8l-0.4,0.1v11.2l0.8-0.8c2.4-2.1,4.8-3.6,9-3.6c4.7,0,7.5,2.6,7.5,6.8v1.4
		c-1.5-1-4.1-1.9-6.9-1.9c-7.9,0-13.4,5-13.4,12.2c0,6.8,5.1,11.4,12.7,11.4c3.4,0,6.5-1.3,8.5-3.6c1.2,2.3,3.6,3.6,6.7,3.6
		c3.8,0,5.7-1.1,7.3-2.3l0.2-0.2v-7.8L265.8,80.4z M247.2,81.7c-2.6,0-4.4-1.5-4.4-3.7c0-2.1,1.8-3.6,4.4-3.6c2.4,0,4.4,1.7,4.4,3.6
		C251.6,80,249.6,81.7,247.2,81.7z"
                  />
                </g>
                <g>
                  <path
                    className="letterR"
                    d="M273.2,90.5c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.5-1.5c0.2-2.6,0.2-5.3,0.2-7.8V60.1h-1.9
		c-0.7,0-1.4-0.4-1.7-1c-0.4-0.6-0.4-1.4,0-2c0.9-1.6,1.9-4.1,2.4-5.8c0.3-0.8,1-1.4,1.9-1.4h11c1.1,0,2,0.9,2,2v0.6
		c2.2-2.1,4.9-3.3,7.9-3.3c1.6,0,2.3,0.1,2.9,0.2c0.3,0.1,0.4,0.1,0.6,0.1c1.1,0,2,0.9,2,2v11.4c0,0.7-0.4,1.4-1.1,1.8
		c-0.3,0.2-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.4c-0.7-0.5-2.1-1.2-4.1-1.2c-3,0-5,4-5,7.6v9.5c0,2.9,0.1,5.4,0.3,7.8
		c0,0.6-0.1,1.1-0.5,1.5s-0.9,0.6-1.5,0.6H273.2z"
                  />
                </g>
                <g>
                  <path
                    className="letterRBehind"
                    d="M297.1,51v12.9l-0.8-0.5c-0.8-0.6-2.5-1.5-5-1.5c-4.2,0-6.5,5.3-6.5,9.1v9.5c0,2.9,0.1,5.5,0.3,7.9
		l0,0.5h-12.5l0-0.5c0.2-2.7,0.2-5.3,0.2-7.9v-22h-4.2l0.4-0.7c0.9-1.7,2-4.3,2.6-6.1l0.1-0.4h11.9v5.8c1.9-4,5.4-6.5,9.4-6.5
		c1.4,0,2.1,0.1,2.6,0.2c0.3,0,0.5,0.1,0.9,0.1H297.1z"
                  />
                </g>
                <g>
                  <path
                    className="letterP"
                    d="M303.5,109.9c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-1-0.5-1.5c0.1-2,0.2-5.6,0.2-7.8V60.1h-1.9
		c-0.7,0-1.3-0.4-1.7-1s-0.4-1.3-0.1-2c0.9-1.6,1.9-4.2,2.5-5.9c0.3-0.8,1-1.3,1.9-1.3h12.1c1.1,0,2,0.9,2,2v0.5
		c2.8-2.1,6.1-3.2,9.8-3.2c10.8,0,19.3,9,19.3,20.5c0,12-8.8,21.4-20,21.4c-3.6,0-6.7-0.8-9.2-2.2v11c0,1.8,0.1,5.5,0.3,7.7
		c0.1,0.6-0.1,1.1-0.5,1.5s-0.9,0.7-1.5,0.7H303.5z M323.6,62.3c-4,0-7.3,3.6-7.3,7.9c0,4.3,3.3,7.9,7.3,7.9c3.9,0,7.1-3.5,7.1-7.9
		C330.8,65.8,327.6,62.3,323.6,62.3z"
                  />
                </g>
                <g>
                  <path
                    className="letterPBehind"
                    d="M326.4,50.7c-4.5,0-8.2,1.7-11.3,5.1v-4.4h-13l-0.1,0.3c-0.6,1.7-1.7,4.4-2.6,6.1l-0.4,0.7h4.2v41.3
		c0,2.2-0.1,5.8-0.2,7.9l0,0.5h12.5l-0.1-0.5c-0.2-2.3-0.3-6.1-0.3-7.9v-14c2.4,2.4,6.2,3.7,10.7,3.7c10.4,0,18.5-8.7,18.5-19.9
		C344.3,59.1,336.5,50.7,326.4,50.7z M323.6,79.5c-4.9,0-8.8-4.2-8.8-9.3c0-5.2,3.9-9.4,8.8-9.4c4.8,0,8.6,4.2,8.6,9.4
		C332.3,75.3,328.4,79.5,323.6,79.5z"
                  />
                </g>
                <g>
                  <path
                    className="letterE"
                    d="M369.4,91.1c-12.3,0-21.5-9-21.5-20.8c0-11.8,8.7-21,19.7-21c12.2,0,19.5,10.3,19.5,20.2
		c0,0.7,0,2.5-0.3,3.4c-0.3,0.8-1,1.4-1.9,1.4h-21.2c1.3,2.4,3.8,4.4,7.5,4.4c4,0,6.3-0.5,8.8-2.1c0.3-0.2,0.7-0.3,1-0.3
		c0.3,0,0.7,0.1,1,0.3c0.6,0.4,1,1,1,1.7v9.1c0,1.1-0.8,1.9-1.9,2c-0.8,0-1.6,0.3-2.8,0.5C376.4,90.4,373.5,91.1,369.4,91.1z
		 M371,62.9c-0.6-1-1.7-1.8-3.4-1.8c-1.6,0-2.8,0.6-3.7,1.8H371z"
                  />
                </g>
                <g>
                  <path
                    className="letterEBehind"
                    d="M367.6,50.8c-10.2,0-18.2,8.6-18.2,19.5c0,11,8.6,19.3,20.1,19.3c3.9,0,6.6-0.6,8.7-1.2
		c1.1-0.3,2.1-0.5,3-0.6l0.5,0V77.3l-0.8,0.5c-2.8,1.7-5.3,2.3-9.6,2.3c-4.8,0-8.6-2.9-9.7-7.4h23.7l0.1-0.3
		c0.2-0.6,0.3-2.1,0.3-2.9C385.6,60.3,378.9,50.8,367.6,50.8z M367.6,59.6c3.4,0,5.1,2.5,5.7,4.8h-11.9
		C362.5,61.3,364.7,59.6,367.6,59.6z"
                  />
                </g>
                <g>
                  <path
                    className="letterT"
                    d="M407.4,91.1c-7.3,0-15.1-4.2-15.1-16V62.8H389c-1.1,0-2-0.9-2-2v-8.9c0-1.1,0.9-2,2-2h3.3v-0.7
		c0-2.6-0.1-5.2-0.2-6.9c0-0.6,0.1-1.1,0.5-1.5c0.4-0.4,0.9-0.6,1.5-0.6h11.3c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,1,0.5,1.5
		c-0.1,1.8-0.2,4.2-0.2,6.9v0.7h7.8c1.1,0,2,0.9,2,2v8.9c0,1.1-0.9,2-2,2h-7.8v10.1c0,5.2,2.3,5.2,3.4,5.2c0.9,0,1.7-0.2,2-0.3
		c0.4-0.3,0.8-0.5,1.3-0.5c0.3,0,0.5,0,0.8,0.2c0.7,0.3,1.2,1,1.2,1.8v9.3c0,1.1-0.9,2-2,2c-0.5,0-1.2,0.1-2,0.2
		C410.6,90.9,409.2,91.1,407.4,91.1z"
                  />
                </g>
                <g>
                  <path
                    className="letterTBehind"
                    d="M405.6,61.3v11.6c0,4.5,1.6,6.7,4.9,6.7c1.3,0,2.6-0.3,3-0.6l0.9-0.9v11h-0.5
		c-0.6,0-1.3,0.1-2.1,0.2c-1.1,0.1-2.6,0.3-4.2,0.3c-8.5,0-13.6-5.4-13.6-14.5V61.3h-5.3v-9.9h5.3v-2.2c0-2.7-0.1-5.3-0.2-7l0-0.5
		h12.4l0,0.5c-0.2,1.8-0.2,4.3-0.2,7v2.2h9.8v9.9H405.6z"
                  />
                </g>
                <g>
                  <path
                    className="letterS"
                    d="M431.6,91.4c-3.3,0-6.6-0.9-9.1-1.5c-1.1-0.3-2.2-0.6-2.7-0.6c-1-0.1-1.8-1-1.8-2V76.5
		c0-0.9,0.5-1.6,1.3-1.9c0.2-0.1,0.4-0.1,0.7-0.1c0.6,0,1.2,0.3,1.6,0.8c1.8,2.2,6.7,4.3,9.3,4.3c1.1,0,1.6-0.3,1.6-0.8
		c0-0.6-2.2-1.9-3.7-2.7c-4-2.3-10.1-5.7-10.1-13.7c0-8.2,5.9-13.1,15.9-13.1c2.8,0,5.2,0.5,7.1,1c0.9,0.2,1.8,0.4,2.3,0.4
		c1,0.1,1.8,1,1.8,2v9.9c0,0.9-0.6,1.6-1.4,1.9c-0.2,0.1-0.4,0.1-0.6,0.1c-0.6,0-1.2-0.3-1.6-0.8c-1.2-1.7-5.1-2.9-7-2.9
		c-1.6,0-1.6,0.5-1.6,0.8c0,0.5,2.3,1.8,3.5,2.4c4.1,2.3,10.4,5.7,10.4,14.1C447.4,86.1,441.2,91.4,431.6,91.4z"
                  />
                </g>
                <g>
                  <path
                    className="letterSBehind"
                    d="M445.9,77.9c0,7.4-5.5,12-14.3,12c-3.1,0-6.3-0.9-8.7-1.5c-1.2-0.3-2.3-0.6-3-0.7l-0.5,0V75.1
		l0.9,1.1c2.1,2.6,7.5,4.9,10.5,4.9c2,0,3.1-0.9,3.1-2.3c0-1.5-2-2.6-4.4-4c-4-2.2-9.4-5.2-9.4-12.4c0-7.3,5.4-11.6,14.4-11.6
		c2.6,0,4.9,0.5,6.7,0.9c1,0.2,1.9,0.4,2.5,0.5l0.5,0V64l-0.9-1.3c-1.7-2.4-6.3-3.6-8.3-3.6c-1.4,0-3.1,0.4-3.1,2.3
		c0,1.4,1.9,2.4,4.2,3.7C440.3,67.4,445.9,70.4,445.9,77.9z"
                  />
                </g>
                <path
                  className="bottomText"
                  d="M78.3,122.7v4.1h-6.4v2.3h4.6v3.9h-4.6v6.2h-5.1v-16.4H78.3z M87.5,135.3h5v3.9H82.4v-16.4h5.1V135.3
	z M100.2,138.2c-1.3-0.7-2.3-1.7-3.1-3s-1.1-2.7-1.1-4.3s0.4-3.1,1.1-4.3s1.8-2.3,3.1-3c1.3-0.7,2.7-1.1,4.3-1.1
	c1.5,0,3,0.4,4.2,1.1c1.3,0.7,2.3,1.7,3,3c0.7,1.3,1.1,2.7,1.1,4.3s-0.4,3.1-1.1,4.3c-0.8,1.3-1.8,2.3-3.1,3s-2.7,1.1-4.2,1.1
	C102.9,139.3,101.5,138.9,100.2,138.2z M106.8,133.5c0.6-0.7,0.8-1.5,0.8-2.7c0-1.1-0.3-2-0.8-2.7c-0.6-0.7-1.3-1-2.4-1
	c-1,0-1.8,0.3-2.4,1c-0.6,0.7-0.8,1.5-0.8,2.7c0,1.1,0.3,2,0.8,2.7c0.6,0.7,1.4,1,2.4,1C105.5,134.5,106.2,134.2,106.8,133.5z
	 M120.8,138.2c-1.3-0.7-2.3-1.7-3.1-3s-1.1-2.7-1.1-4.3s0.4-3.1,1.1-4.3s1.8-2.3,3.1-3c1.3-0.7,2.7-1.1,4.3-1.1c1.5,0,3,0.4,4.2,1.1
	c1.3,0.7,2.3,1.7,3,3c0.7,1.3,1.1,2.7,1.1,4.3s-0.4,3.1-1.1,4.3c-0.8,1.3-1.8,2.3-3.1,3c-1.3,0.7-2.7,1.1-4.2,1.1
	C123.5,139.3,122.1,138.9,120.8,138.2z M127.4,133.5c0.6-0.7,0.8-1.5,0.8-2.7c0-1.1-0.3-2-0.8-2.7c-0.6-0.7-1.3-1-2.4-1
	c-1,0-1.8,0.3-2.4,1c-0.6,0.7-0.8,1.5-0.8,2.7c0,1.1,0.3,2,0.8,2.7c0.6,0.7,1.4,1,2.4,1C126,134.5,126.8,134.2,127.4,133.5z
	 M145.9,139.1l-3.1-5.9h0v5.9h-5.1v-16.4h7.7c1.3,0,2.5,0.2,3.4,0.7c0.9,0.5,1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.7,0.7,2.7
	c0,1.1-0.3,2.1-0.9,2.9c-0.6,0.8-1.5,1.5-2.6,1.8l3.6,6.3H145.9z M142.8,129.8h2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1
	c0-0.4-0.1-0.8-0.4-1c-0.3-0.2-0.6-0.4-1.1-0.4h-2V129.8z M160.6,122.7v16.4h-5.1v-16.4H160.6z M181,139.1h-5.1l-5.4-8.3v8.3h-5.1
	v-16.4h5.1l5.4,8.4v-8.4h5.1V139.1z M195.7,128.3c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3c-1,0-1.8,0.3-2.3,1
	c-0.5,0.6-0.8,1.5-0.8,2.6c0,1.3,0.3,2.2,0.9,2.9c0.6,0.6,1.5,0.9,2.8,0.9c1.2,0,2.1-0.5,2.9-1.4h-4.1v-3.6h8.4v5.1
	c-0.7,1.2-1.7,2.3-2.9,3.1c-1.3,0.9-2.8,1.3-4.8,1.3c-1.7,0-3.2-0.4-4.4-1.1s-2.2-1.7-2.9-3c-0.7-1.3-1-2.7-1-4.3s0.3-3.1,1-4.3
	s1.6-2.3,2.9-3c1.3-0.7,2.7-1.1,4.4-1.1c2.1,0,3.9,0.5,5.3,1.6c1.4,1,2.2,2.4,2.4,4.2H195.7z M224.1,139.1l-1.1-1.1
	c-1.5,0.9-3.2,1.3-5.1,1.3c-1.3,0-2.5-0.2-3.5-0.6s-1.7-1-2.3-1.8c-0.5-0.8-0.8-1.6-0.8-2.6c0-0.9,0.2-1.8,0.7-2.6s1.2-1.4,2.2-2
	c-0.7-0.9-1.1-1.8-1.1-2.8c0-0.8,0.2-1.6,0.7-2.3s1.1-1.2,1.9-1.7c0.9-0.4,1.9-0.6,3-0.6c1.2,0,2.2,0.2,3,0.6c0.8,0.4,1.5,1,1.9,1.7
	c0.4,0.7,0.6,1.4,0.6,2.2h-4.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
	c0,0.5,0.3,1.1,1,1.7l3.7,3.6c0.2-0.4,0.4-0.9,0.6-1.4l0.1-0.2h5l-0.2,0.6c-0.6,1.5-1.3,2.9-2.2,4.1l3.9,3.8H224.1z M219.8,135.1
	l-2.7-2.6c-0.3,0.2-0.5,0.4-0.6,0.6c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.4,0.2,0.7,0.5,1c0.3,0.3,0.8,0.4,1.4,0.4
	C218.8,135.3,219.3,135.2,219.8,135.1z M240,126.6c0.7-1.3,1.6-2.3,2.8-3c1.2-0.7,2.7-1.1,4.3-1.1c1.4,0,2.7,0.3,3.8,0.8
	s2,1.3,2.7,2.3c0.7,1,1.2,2.1,1.4,3.4h-5.4c-0.2-0.5-0.6-1-1.1-1.3c-0.5-0.3-1-0.5-1.6-0.5c-0.9,0-1.5,0.3-2.1,1
	c-0.5,0.7-0.8,1.5-0.8,2.6s0.3,2,0.8,2.6s1.2,1,2.1,1c0.6,0,1.1-0.2,1.6-0.5s0.8-0.7,1.1-1.3h5.4c-0.2,1.3-0.7,2.4-1.4,3.4
	c-0.7,1-1.6,1.7-2.7,2.3s-2.4,0.8-3.8,0.8c-1.7,0-3.1-0.4-4.3-1.1c-1.2-0.7-2.2-1.7-2.8-3c-0.7-1.3-1-2.7-1-4.3
	S239.3,127.9,240,126.6z M263,138.2c-1.3-0.7-2.3-1.7-3.1-3c-0.8-1.3-1.1-2.7-1.1-4.3s0.4-3.1,1.1-4.3c0.8-1.3,1.8-2.3,3.1-3
	c1.3-0.7,2.7-1.1,4.3-1.1s3,0.4,4.2,1.1c1.3,0.7,2.3,1.7,3,3c0.7,1.3,1.1,2.7,1.1,4.3s-0.4,3.1-1.1,4.3c-0.8,1.3-1.8,2.3-3.1,3
	c-1.3,0.7-2.7,1.1-4.2,1.1C265.7,139.3,264.3,138.9,263,138.2z M269.6,133.5c0.6-0.7,0.8-1.5,0.8-2.7c0-1.1-0.3-2-0.8-2.7
	c-0.6-0.7-1.3-1-2.4-1c-1,0-1.8,0.3-2.4,1c-0.6,0.7-0.8,1.5-0.8,2.7c0,1.1,0.3,2,0.8,2.7c0.6,0.7,1.4,1,2.4,1
	C268.3,134.5,269.1,134.2,269.6,133.5z M284.9,122.7v9.3c0,0.8,0.2,1.4,0.5,1.8c0.3,0.4,0.9,0.7,1.6,0.7c0.7,0,1.3-0.2,1.6-0.7
	c0.4-0.4,0.5-1,0.5-1.8v-9.3h5.1v9.3c0,1.6-0.3,2.9-1,4c-0.6,1.1-1.5,1.9-2.7,2.5c-1.1,0.6-2.4,0.8-3.8,0.8c-1.4,0-2.6-0.3-3.7-0.8
	c-1.1-0.6-1.9-1.4-2.5-2.5c-0.6-1.1-0.9-2.4-0.9-4v-9.3H284.9z M314.6,139.1h-5.1l-5.4-8.3v8.3h-5.1v-16.4h5.1l5.4,8.4v-8.4h5.1
	V139.1z M332.1,122.7v4.1h-4.4v12.3h-5.1v-12.3h-4.3v-4.1H332.1z M341,126.8v2h5.1v3.9H341v2.3h5.8v4.1h-10.9v-16.4h10.9v4.1H341z
	 M359.3,139.1l-3.1-5.9h0v5.9H351v-16.4h7.7c1.3,0,2.5,0.2,3.4,0.7c0.9,0.5,1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.7,0.7,2.7
	c0,1.1-0.3,2.1-0.9,2.9c-0.6,0.8-1.5,1.5-2.6,1.8l3.6,6.3H359.3z M356.2,129.8h2c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.6,0.4-1.1
	c0-0.4-0.1-0.8-0.4-1c-0.3-0.2-0.6-0.4-1.1-0.4h-2V129.8z M381.8,122.7v4.1h-4.4v12.3h-5.1v-12.3h-4.3v-4.1H381.8z M389.2,138.2
	c-1.3-0.7-2.3-1.7-3.1-3c-0.8-1.3-1.1-2.7-1.1-4.3s0.4-3.1,1.1-4.3c0.8-1.3,1.8-2.3,3.1-3c1.3-0.7,2.7-1.1,4.3-1.1
	c1.5,0,3,0.4,4.2,1.1s2.3,1.7,3,3s1.1,2.7,1.1,4.3s-0.4,3.1-1.1,4.3s-1.8,2.3-3.1,3c-1.3,0.7-2.7,1.1-4.2,1.1
	C391.9,139.3,390.5,138.9,389.2,138.2z M395.8,133.5c0.6-0.7,0.8-1.5,0.8-2.7c0-1.1-0.3-2-0.8-2.7s-1.3-1-2.4-1c-1,0-1.8,0.3-2.4,1
	s-0.8,1.5-0.8,2.7c0,1.1,0.3,2,0.8,2.7c0.6,0.7,1.4,1,2.4,1C394.5,134.5,395.3,134.2,395.8,133.5z M418.8,131.2
	c-0.5,0.8-1.2,1.5-2.1,2c-0.9,0.5-2.1,0.7-3.4,0.7h-2v5.2h-5.1v-16.4h7.2c2,0,3.6,0.5,4.6,1.5s1.6,2.4,1.6,4.1
	C419.5,129.4,419.2,130.3,418.8,131.2z M414.3,128.4c0-1-0.5-1.5-1.6-1.5h-1.5v3h1.5C413.7,129.9,414.3,129.4,414.3,128.4z
	 M425,137.9c-1.3-0.9-2-2.3-2.1-4h5.5c0.1,0.9,0.5,1.4,1.3,1.4c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6
	c0-0.4-0.2-0.7-0.6-0.9c-0.4-0.2-1.1-0.5-1.9-0.8c-1.1-0.4-1.9-0.7-2.6-1.1c-0.7-0.4-1.3-0.9-1.8-1.6c-0.5-0.7-0.7-1.6-0.7-2.6
	c0-1.1,0.3-2,0.8-2.7s1.3-1.3,2.2-1.7c1-0.4,2-0.6,3.2-0.6c2,0,3.6,0.5,4.8,1.4s1.8,2.2,1.9,3.9h-5.5c0-0.5-0.1-0.8-0.3-1
	c-0.2-0.2-0.5-0.3-0.8-0.3c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.4,0.2,0.7,0.6,0.9c0.4,0.2,1.1,0.5,2,0.9
	c1,0.4,1.9,0.8,2.6,1.1c0.7,0.4,1.3,0.8,1.8,1.5c0.5,0.6,0.7,1.4,0.7,2.4c0,1-0.2,1.9-0.7,2.7c-0.5,0.8-1.2,1.4-2.2,1.9
	c-0.9,0.4-2.1,0.7-3.4,0.7C427.9,139.3,426.3,138.9,425,137.9z"
                />
              </svg>
            </div>
            <StaticModal />
            {/* table design start */}
            <StaticTable />
            {/* table design start */}
            {/* tooltip */}
            <div className="sec__wrapper checkbox__sec mb-[40px]">
              <h2 className="text-[24px] font-Biotif__SemiBold text-black mb-[50px]">
                Tooltip:
              </h2>
              <Tippy
                content="This is a tooltip"
                theme="black__tooltipSD"
                visible
              >
                <span className="text-[16px] font-Biotif__Regular text-textDark mr-[50px]">
                  This is a tooltip
                </span>
              </Tippy>
              <Tippy
                content="This is a tooltip"
                theme="white__tooltipSD"
                visible
              >
                <span className="text-[16px] font-Biotif__Regular text-textDark mr-[50px]">
                  This is a tooltip
                </span>
              </Tippy>
              <Tippy
                content="This is a tooltip"
                theme="primary__tooltipSD"
                visible
              >
                <span className="text-[16px] font-Biotif__Regular text-textDark mr-[50px]">
                  This is a tooltip
                </span>
              </Tippy>
            </div>
            {/* tooltip-end */}
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Badge:
            </h3>
            <div className="mb-[30px] flex flex-wrap">
              <div className="mb-[10px] mr-[15px]">
                <div className="tags__badge">
                  <span className="tags__text">Lorem ipsume</span>
                  <span className="close__btn">
                    <Icon name="close" />
                  </span>
                </div>
              </div>
              <div className="mb-[10px] mr-[15px]">
                <div className="tags__badge tags__badgePrimary">
                  <span className="tags__text">Lorem ipsume</span>
                  <span className="close__btn">
                    <Icon name="close" />
                  </span>
                </div>
              </div>
              <div className="mb-[10px] mr-[15px]">
                <div className="tags__badge tags__badgeSuccess">
                  <span className="tags__text">Lorem ipsume</span>
                  <span className="close__btn">
                    <Icon name="close" />
                  </span>
                </div>
              </div>
              <div className="mb-[10px] mr-[15px]">
                <div className="tags__badge tags__badgeDanger">
                  <span className="tags__text">Lorem ipsume</span>
                  <span className="close__btn">
                    <Icon name="close" />
                  </span>
                </div>
              </div>
              <div className="mb-[10px] mr-[15px]">
                <div className="tags__badge tags__badgeWarning">
                  <span className="tags__text">Lorem ipsume</span>
                  <span className="close__btn">
                    <Icon name="close" />
                  </span>
                </div>
              </div>
            </div>

            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Small Spinner:
            </h3>
            <div className="mb-[30px]">
              <Spinner />
            </div>

            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Page Spinner:
            </h3>
            <div className="mb-[30px]">
              <PageLoader />
            </div>

            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Buttons:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap mx-[-20px]">
                <div className="w-1/3 px-[20px]">
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="primaryBtn iconLeft" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="primaryBtn" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="primaryBtn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="secondaryBtn iconLeft"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="secondaryBtn" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="secondaryBtn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="primary__outline__Btn iconLeft"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="primary__outline__Btn"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="primary__outline__Btn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="primary__ghost__Btn iconLeft"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="primary__ghost__Btn" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="primary__ghost__Btn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/3 px-[20px]">
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="dangerBtn iconLeft" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="dangerBtn" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="dangerBtn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="dangerSecondaryBtn iconLeft"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="dangerSecondaryBtn" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="dangerSecondaryBtn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="danger__ghost__Btn iconLeft"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button className="danger__ghost__Btn" icon="plusCircle">
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="danger__ghost__Btn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="danger__outline__Btn iconLeft"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="danger__outline__Btn"
                        icon="plusCircle"
                      >
                        Add New
                      </Button>
                    </div>
                    <div className="mr-[20px] mb-[15px]">
                      <Button
                        className="danger__outline__Btn onlyIcon"
                        icon="plusCircle"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Normal Dropdown:
            </h3>
            <div className="mb-[30px]">
              <div className="carperDropdown">
                <div className="carperDropdown__inner">
                  <div className="carperDropdown__item">
                    <div className="carperDropdown__link">
                      <Icon name="user" />
                      <span className="carperDropdown__name">Profile</span>
                    </div>
                  </div>
                  <div className="carperDropdown__item">
                    <div className="carperDropdown__link">
                      <Icon name="logout" />
                      <span className="carperDropdown__name">Log Out</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Input Fields:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField placeholder="Search" label="Label Text" />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    placeholder="Search"
                    label="Label Text"
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="search"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="field__has__error"
                    placeholder="Search"
                    label="Label Text"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="field__has__error"
                    placeholder="Search"
                    label="Label Text"
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="field__has__error"
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="search"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="field__has__success"
                    placeholder="Search"
                    label="Label Text"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="field__has__success"
                    placeholder="Search"
                    label="Label Text"
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="field__has__success"
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="search"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldBGClassName="bg-bgWhiteCarpet"
                    fieldWrapperClassName="carperFieldBox"
                    placeholder="Search"
                    label="Label Text"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldBGClassName="bg-bgWhiteCarpet"
                    fieldWrapperClassName="carperFieldBox"
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldBGClassName="bg-bgWhiteCarpet"
                    fieldWrapperClassName="carperFieldBox"
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox"
                    placeholder="Search"
                    label="Label Text"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox"
                    placeholder="Search"
                    label="Label Text dsfsdfssf dsfsfsdfsdf"
                    icon="calendar"
                    name="xty"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox"
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox field__has__error"
                    placeholder="Search"
                    label="Label Text"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox field__has__error"
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox field__has__error"
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox field__has__success"
                    placeholder="Search"
                    label="Label Text"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox field__has__success"
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <InputField
                    fieldWrapperClassName="carperFieldBox field__has__success"
                    isIconRight
                    placeholder="Search"
                    label="Label Text"
                    icon="calendar"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Phone Fields:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    fieldWrapperClassName="field__has__error"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    fieldWrapperClassName="field__has__error"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    fieldWrapperClassName="field__has__error"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    fieldWrapperClassName="field__has__success"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    fieldWrapperClassName="field__has__success"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    fieldWrapperClassName="field__has__success"
                    // fieldWrapperClassName="carperFieldBox"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    fieldWrapperClassName="carperFieldBox"
                    fieldBGClassName="bg-bgWhiteCarpet"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    fieldWrapperClassName="carperFieldBox"
                    fieldBGClassName="bg-bgWhiteCarpet"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    fieldWrapperClassName="carperFieldBox"
                    fieldBGClassName="bg-bgWhiteCarpet"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    fieldWrapperClassName="carperFieldBox"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    fieldWrapperClassName="carperFieldBox field__has__error"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    fieldWrapperClassName="carperFieldBox field__has__error"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    fieldWrapperClassName="carperFieldBox field__has__error"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    fieldWrapperClassName="carperFieldBox field__has__success"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    fieldWrapperClassName="carperFieldBox field__has__success"
                  />
                </div>
                <div className="w-1/3 px-[15px] mb-[15px]">
                  <PhoneField
                    name="phone"
                    label="Primary Phone"
                    placeholder="(789) 878-7878"
                    icon="calendar"
                    isIconRight
                    fieldWrapperClassName="carperFieldBox field__has__success"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Textarea:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField placeholder="Search" label="Label Text" />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField placeholder="Search" label="Label Text" />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField placeholder="Search" label="Label Text" />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="field__has__error"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="field__has__error"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="field__has__error"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="field__has__success"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="field__has__success"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="field__has__success"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox whiteBGTextarea"
                    fieldBGClassName="bg-bgWhiteCarpet"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox whiteBGTextarea"
                    fieldBGClassName="bg-bgWhiteCarpet"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox whiteBGTextarea"
                    fieldBGClassName="bg-bgWhiteCarpet"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox field__has__error"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox field__has__error"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox field__has__error"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mx-[-15px]">
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox field__has__success"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox field__has__success"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <TextAreaField
                    placeholder="Search"
                    label="Label Text"
                    fieldWrapperClassName="carperFieldBox field__has__success"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Select Fields:
            </h3>
            <div className="mb-[30px]">
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="defaultGrayBorder"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="defaultGrayBorder"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="defaultGrayBorder"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="field__has__error"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="field__has__error"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="field__has__error"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="field__has__success"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="field__has__success"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="search"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="field__has__success"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldBGClassName="bg-bgWhiteCarpet"
                    fieldWrapperClassName="carperFieldBox"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldBGClassName="bg-bgWhiteCarpet"
                    fieldWrapperClassName="carperFieldBox"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox"
                    fieldBGClassName="bg-bgWhiteCarpet"
                    label="Account Number"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox"
                    label="Account Number"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox field__has__error"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox field__has__error"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox field__has__error"
                    label="Account Number"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
              </div>
              <div className="mx-[-15px] flex flex-wrap mb-[20px]">
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox field__has__success"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox field__has__success"
                    label="Account Number"
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
                <div className="w-1/3 px-[15px]">
                  <SelectField
                    fieldWrapperClassName="carperFieldBox field__has__success"
                    label="Account Number"
                    isCreatable
                    isMulti
                    options={[
                      { value: "chocolate", label: "Chocolate" },
                      { value: "strawberry", label: "Strawberry" },
                      { value: "vanilla", label: "Vanilla" },
                      { value: "vanillaone", label: "Vanilla one" },
                      { value: "vanillatwo", label: "Vanilla two" },
                      { value: "vanillathree", label: "Vanilla three" },
                    ]}
                    icon="calendar"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Avatar:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap">
                <div className="mr-[20px] mb-[15px]">
                  <div className="avatar__Img w-[60px] h-[60px]">
                    <div className="inner__wrapper !rounded-full">
                      <div className="img__element">
                        <img src="/images/carpet-img.jpg" alt="" />
                      </div>
                    </div>
                    <span className="online__status" />
                  </div>
                </div>
                <div className="mr-[20px] mb-[15px]">
                  <div className="avatar__Img w-[60px] h-[60px]">
                    <div className="inner__wrapper !rounded-full">
                      <div className="noImg__icon">
                        <div className="iconWrapper">
                          <div className="svgWrapper">
                            <Setting />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="online__status" />
                  </div>
                </div>
                <div className="mr-[20px] mb-[15px]">
                  <div className="avatar__Img w-[60px] h-[60px]">
                    <div className="inner__wrapper !rounded-full">
                      <div className="img__loader__wrapper">
                        <div className="inner__wrapper">
                          <div className="smallLoaderWrapper inline-flex items-center justify-center">
                            <span className="smallLoader inline-block w-0 h-0 p-[7px] rounded-full border-[3px] border-gray-300 border-r-primaryColor relative animate-smalLoaderCarpet" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="online__status" />
                  </div>
                </div>
                <div className="mr-[20px] mb-[15px]">
                  <div className="avatar__Img w-[60px] h-[60px]">
                    <div className="inner__wrapper !rounded-full">
                      <div className="noName__letter">
                        <div className="noName__letter__text !text-[22px] leading-[22px]">
                          DP
                        </div>
                      </div>
                    </div>
                    <span className="online__status" />
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Multiple Avatar:
            </h3>
            <div className="mb-[30px]">
              <div className="multiple__avatar__wrapper">
                <div className="avatar__Img">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="avatar__Img">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="avatar__Img">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="avatar__Img">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="avatar__Img">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="avatar__Img">
                  <div className="inner__wrapper !rounded-full">
                    <div className="noName__letter">
                      <div className="noName__letter__text">DP</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Toast:
            </h3>
            <div className="mb-[30px]">
              <div className="mb-[15px]">
                <div className="toastItem toast__default show">
                  <div className="innerWrapper">
                    <Icon className="typeIcon" name="setting" />
                    <span className="toastText">
                      This is toast notification text
                    </span>
                    <Icon className="closeBtn" name="closeBold" />
                  </div>
                </div>
              </div>
              <div className="mb-[15px]">
                <div className="toastItem success show">
                  <div className="innerWrapper">
                    <Icon className="typeIcon" name="setting" />
                    <span className="toastText">
                      This is toast notification text
                    </span>
                    <Icon className="closeBtn" name="closeBold" />
                  </div>
                </div>
              </div>
              <div className="mb-[15px]">
                <div className="toastItem error show">
                  <div className="innerWrapper">
                    <Icon className="typeIcon" name="setting" />
                    <span className="toastText">
                      This is toast notification text
                    </span>
                    <Icon className="closeBtn" name="closeBold" />
                  </div>
                </div>
              </div>
              <div className="mb-[15px]">
                <div className="toastItem warning show">
                  <div className="innerWrapper">
                    <Icon className="typeIcon" name="setting" />
                    <span className="toastText">
                      This is toast notification text
                    </span>
                    <Icon className="closeBtn" name="closeBold" />
                  </div>
                </div>
              </div>
              <div className="toastWrapper hidden">
                <div className="toastCN__absolute">
                  <div className="toastItem toast__default show">
                    <div className="innerWrapper">
                      <Icon className="typeIcon" name="setting" />
                      <span className="toastText">
                        This is toast notification text
                      </span>
                      <Icon className="closeBtn" name="closeBold" />
                    </div>
                  </div>
                  <div className="toastItem success show">
                    <div className="innerWrapper">
                      <Icon className="typeIcon" name="setting" />
                      <span className="toastText">
                        This is toast notification text
                      </span>
                      <Icon className="closeBtn" name="closeBold" />
                    </div>
                  </div>
                  <div className="toastItem error show">
                    <div className="innerWrapper">
                      <Icon className="typeIcon" name="setting" />
                      <span className="toastText">
                        This is toast notification text
                      </span>
                      <Icon className="closeBtn" name="closeBold" />
                    </div>
                  </div>
                  <div className="toastItem warning show">
                    <div className="innerWrapper">
                      <Icon className="typeIcon" name="setting" />
                      <span className="toastText">
                        This is toast notification text
                      </span>
                      <Icon className="closeBtn" name="closeBold" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Color Picker:
            </h3>
            <div className="mb-[30px] flex items-start">
              <div className="inline-flex mr-[15px] mb-[10px]">
                <div className="colorPicker__Carpet">
                  <div
                    className="colorPicker__CarpetInner"
                    style={{ backgroundColor: "#5348D4" }}
                  >
                    <input type="color" />
                    <Icon name="checkArrowBold" />
                  </div>
                </div>
              </div>
              <div className="inline-flex mr-[20px] mb-[10px]">
                <div className="colorPicker__Carpet">
                  <div
                    className="colorPicker__CarpetInner"
                    style={{ backgroundColor: "#f78310" }}
                  >
                    <input type="color" />
                  </div>
                </div>
              </div>
              <div className="inline-flex mr-[20px] mb-[10px]">
                <div className="colorPicker__Carpet">
                  <div
                    className="colorPicker__CarpetInner"
                    style={{ backgroundColor: "#24bd63" }}
                  >
                    <input type="color" />
                  </div>
                </div>
              </div>
              <div className="inline-flex mr-[20px] mb-[10px]">
                <div className="colorPicker__Carpet">
                  <div
                    className="colorPicker__CarpetInner"
                    style={{ backgroundColor: "#d32f2f" }}
                  >
                    <input type="color" />
                  </div>
                </div>
              </div>
              <div className="inline-flex mr-[20px] mb-[10px]">
                <div className="colorPicker__Carpet">
                  <div className="colorPicker__CarpetInner">
                    <img
                      className="color__picker__img"
                      src="/images/colorPickerImg.png"
                      alt=""
                    />
                    <input type="color" />
                  </div>
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Checkbox:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap">
                <div className="inline-block mr-[25px]">
                  <CheckBoxField fieldLabelClass="!hidden" label="Input text" />
                </div>
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="customCheckbox__carpetMedium"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="customCheckbox__carpetLarge"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="label__left"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="customCheckbox__carpetMedium label__left"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="customCheckbox__carpetLarge label__left"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="without__label"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="customCheckbox__carpetMedium without__label"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <CheckBoxField
                    wrapperClass="customCheckbox__carpetLarge without__label"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Radio Button:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap">
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="customRadio__carpetMedium"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="customRadio__carpetLarge"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="label__left"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="customRadio__carpetMedium label__left"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="customRadio__carpetLarge label__left"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="without__label"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="customRadio__carpetMedium without__label"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
                <div className="inline-block mr-[25px]">
                  <RadioButtonField
                    wrapperClass="customRadio__carpetLarge without__label"
                    fieldLabelClass="!hidden"
                    label="Input text"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Toggle Buttons:
            </h3>
            <div className="mb-[30px]">
              <div className="flex flex-wrap mb-[15px] bg-gray-200 pt-[15px] px-[15px]">
                <div className="inline-block mr-[25px] mb-[10px]">
                  <ToggleButtonField name="toggleBtn1" label="Input Label" />
                </div>
                <div className="inline-block mr-[25px] mb-[10px]">
                  <ToggleButtonField
                    wrapperClass="toggleSwitch__btnWhite"
                    name="toggleBtn2"
                    label="Input Label"
                  />
                </div>
                <div className="inline-block mr-[25px] mb-[10px]">
                  <ToggleButtonField
                    wrapperClass="toggleSwitch__btnLeft"
                    name="toggleBtn3"
                    label="Input Label"
                  />
                </div>
                <div className="inline-block mr-[25px] mb-[10px]">
                  <ToggleButtonField
                    wrapperClass="toggleSwitch__btnLeft toggleSwitch__btnWhite"
                    name="toggleBtn4"
                    label="Input Label"
                  />
                </div>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              File Upload:
            </h3>
            <div className="mb-[30px]">dsdsahidsd</div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Shadows:
            </h3>
            <div className="flex flex-wrap mb-[30px]">
              <div className="w-[150px] mb-[25px] mr-[45px]">
                <div className="w-full pt-[100%] relative rounded-[10px] bg-bgWhiteCarpet shadow-raiseShadow" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  raiseShadow
                </p>
              </div>
              <div className="w-[150px] mb-[15px] mr-[45px]">
                <div className="w-full pt-[100%] relative rounded-[10px] bg-bgWhiteCarpet shadow-dropDownShadow" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  dropDownShadow
                </p>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Colors:
            </h3>
            <div className="flex flex-wrap mb-[30px]">
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-primaryColor before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  primaryColor
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-primaryColorDark before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  primaryColorDark
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-successCarpet before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  successCarpet
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-successDarkCarpet before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  successDarkCarpet
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-dangerCarpet before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  dangerCarpet
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-dangerDarkCarpet before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  dangerDarkCarpet
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-warningCarpet before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  warningCarpet
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-warningDarkCarpet before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  warningDarkCarpet
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[10px] pb-[14px] mb-[15px] mr-[15px]">
                <div className="w-full pt-[100%] relative rounded-[8px] overflow-hidden before:content-[''] before:bg-textDark before:absolute before:top-0 before:left-0 before:w-full before:h-full" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  textDark
                </p>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Stroke Icons:
            </h3>
            <div className="flex flex-wrap mb-[30px]">
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="addFile" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  addFile
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="addFolder"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  addFolder
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="addUser" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  addUser
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="calendar" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  calendar
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="estimate" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  estimate
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="expenses" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  expenses
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="file" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  file
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="graph"
                  className="w-[100px] h-[100px] mx-auto"
                  fill="#FEFE"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  graph
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="handShake"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  handShake
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="home" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  home
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="invoice" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  invoice
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="lead" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  lead
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="lock" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  lock
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="order" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  order
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="product" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  product
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="setting" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  setting
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="user" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  user
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="users" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  users
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="arrowDownBold"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  arrowDownBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="arrowUpBold"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  arrowUpBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="bell" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  bell
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="checkArrow"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  checkArrow
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="checkArrowBold"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  checkArrowBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="close" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  close
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="closeBold"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  closeBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="dotsThreeVertical"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  dotsThreeVertical
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="plusCircle"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  plusCircle
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="search" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  search
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="envelopeSimple"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  envelopeSimple
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="logout" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  logout
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="watch" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  watch
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="gmailSquareBox"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  gmailSquareBox
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="noteBig" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  noteBig
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="m2" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  m2
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="filterFunnel"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  filterFunnel
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="plus" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  plus
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="report1" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  report1
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="report2" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  report2
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="report3" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  report3
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="report4" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  report4
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="report5" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  report5
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="caretLeft"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  caretLeft
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="caretRight"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  caretRight
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="caretLeftBold"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  caretLeftBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="caretRightBold"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  caretRightBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="imageSquare"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  imageSquare
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="uploadSimple"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  uploadSimple
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="trash" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  trash
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="copy" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  copy
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="pricing" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  pricing
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="pencilSimple"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  pencilSimple
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="eye" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  eye
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="sealDiscount"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  sealDiscount
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="warning" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  warning
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="caretDown" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  caretDown
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="caretDownBold" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                caretDownBold
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="viewPro" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  viewPro
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="editPro" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  editPro
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="deletePro" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  deletePro
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="inStock" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  inStock
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="link" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  link
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="money" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  Money
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon name="export" className="w-[100px] h-[100px] mx-auto" />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  Export
                </p>
              </div>
            </div>
            <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[8px]">
              Filled Icons:
            </h3>
            <div className="flex flex-wrap mb-[30px]">
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="horizontalNFilled"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  horizontalNFilled
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="paperPlaneRightFilled"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  paperPlaneRightFilled
                </p>
              </div>
              <div className="w-[200px] bg-bgWhiteCarpet shadow-raiseShadow rounded-[10px] p-[20px] mb-[15px] mr-[15px]">
                <Icon
                  name="dotsNineFilled"
                  className="w-[100px] h-[100px] mx-auto"
                />
                <p className="text-center font-AcuminPro__Semibold mt-[12px]">
                  DotsNineFilled
                </p>
              </div>
            </div>
          </div>
          {/* static theme guide page start */}

          {/* add team page start */}
          <div className="addTeam__page hidden">
            <div className="horizontalTab mb-[30px]">
              <div className="horizontalTab__inner">
                <div className="horizontalTab__item active">
                  <span className="horizontalTab__link">
                    <span className="horizontalTab__name">User Details</span>
                  </span>
                </div>
                <div className="horizontalTab__item">
                  <span className="horizontalTab__link">
                    <span className="horizontalTab__name">User Image</span>
                  </span>
                </div>
                <div className="horizontalTab__item">
                  <span className="horizontalTab__link">
                    <span className="horizontalTab__name">Availability</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="addTeam__tabCN h-[calc(100dvh_-_282px)] overflow-y-auto hideScrollbar">
              <div className="userDetails w-[660px] max-w-full mx-auto bg-bgWhiteCarpet rounded-[20px] p-[32px]">
                <div className="mx-[-15px] flex flex-wrap">
                  <div className="w-1/2 px-[15px]">
                    <InputField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="First Name"
                    />
                  </div>
                  <div className="w-1/2 px-[15px]">
                    <InputField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="Last Name"
                    />
                  </div>
                  <div className="w-1/2 px-[15px]">
                    <NumberField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="Primary Phone"
                    />
                  </div>
                  <div className="w-1/2 px-[15px]">
                    <EmailField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="Email Address"
                    />
                  </div>
                  <div className="w-1/2 px-[15px]">
                    <InputField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="Company Name"
                    />
                  </div>
                  <div className="w-1/2 px-[15px]">
                    <SelectField
                      fieldWrapperClassName="carperFieldBox"
                      label="Role"
                      options={[
                        { value: "chocolate", label: "Chocolate" },
                        { value: "strawberry", label: "Strawberry" },
                        { value: "vanilla", label: "Vanilla" },
                        { value: "vanillaone", label: "Vanilla one" },
                        { value: "vanillatwo", label: "Vanilla two" },
                        { value: "vanillathree", label: "Vanilla three" },
                      ]}
                    />
                  </div>
                  <div className="w-full px-[15px]">
                    <div className="addressField__wrapper relative">
                      <InputField
                        fieldWrapperClassName="carperFieldBox"
                        placeholder="Search"
                        label="Address"
                      />
                      <span className="text-[14px] text-primaryColor font-AcuminPro__Regular cursor-pointer absolute top-[12px] right-[15px] z-[5] hover:underline">
                        +Add Shipping
                      </span>
                    </div>
                  </div>
                  <div className="w-1/3 px-[15px]">
                    <InputField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="City"
                    />
                  </div>
                  <div className="w-1/3 px-[15px]">
                    <SelectField
                      fieldWrapperClassName="carperFieldBox"
                      label="State"
                      options={[
                        { value: "chocolate", label: "Chocolate" },
                        { value: "strawberry", label: "Strawberry" },
                        { value: "vanilla", label: "Vanilla" },
                        { value: "vanillaone", label: "Vanilla one" },
                        { value: "vanillatwo", label: "Vanilla two" },
                        { value: "vanillathree", label: "Vanilla three" },
                      ]}
                    />
                  </div>
                  <div className="w-1/3 px-[15px]">
                    <InputField
                      fieldWrapperClassName="carperFieldBox"
                      placeholder="Search"
                      label="Zip"
                    />
                  </div>
                </div>
              </div>
              <div className="userImage flex justify-center hidden">
                <div className="inline-block bg-bgWhiteCarpet rounded-[20px] p-[32px]">
                  <div className="fileUpload__dropzone w-[400px] h-[400px] relative rounded-[16px] border-[2px] border-dashed border-borderPrimary flex items-center justify-center">
                    <input
                      className="absolute top-0 left-0 w-full h-full z-[3] opacity-0"
                      type="file"
                    />
                    <p className="font-AcuminPro__Regular text-[20px] text-textDark w-[192px] max-w-full mx-auto text-center">
                      Drag image here or click to upload
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end pt-[30px]">
              <Button className="primary__ghost__Btn min-w-[120px]">
                Cancel
              </Button>
              <Button className="primaryBtn min-w-[120px] ml-[12px]">
                Save
              </Button>
            </div>
          </div>
          {/* add team page end */}

          {/* report page start */}
          <div className="hidden">
            <ReportStaticPage />
          </div>
          {/* report page end */}
        </div>
      </div>
    </div>

    // alert-modal

    // alert-modal-end
  );
};

export default StaticDashboard;
