// ** Packages **
import { useNavigate } from "react-router-dom";

// ** Components **
import Button from "components/Button";
import Icon from "components/Icon";
import AuthGuard from "modules/Auth/components/AuthGuard";

// ** Hook **
import { useToggleDropdown } from "hooks/useToggleDropdown";

// ** Constants **
import { ACTION } from "constants/entity.constant";
import { QUICK_ADD } from "../constants";

const QuickAdd = () => {
  const navigate = useNavigate();
  const { dropdownRef, isDropdownOpen, toggleDropdown } = useToggleDropdown();

  return (
    <div className="inline-block relative shrink-0 z-[11]" ref={dropdownRef}>
      <Button
        className="primaryBtn iconLeft"
        icon="plusCircle"
        onClick={() => toggleDropdown()}
      >
        Add new
      </Button>
      <div
        className={`carperDropdown topHeader__addNew__dropdown absolute top-[100%] right-0 pt-[10px] z-[7] lg:right-unset lg:left-0 md:right-0 md:left-unset ${
          isDropdownOpen ? "" : "hidden"
        }`}
      >
        <div className="carperDropdown__inner w-[410px] sm:w-[220px]">
          {QUICK_ADD.map(({ icon, label, path, module }, index) => {
            return module ? (
              <AuthGuard
                moduleName={module}
                permissionName={ACTION.CREATE}
                key={`${label}-${index}`}
              >
                <div className="carperDropdown__item" key={`${label}-${index}`}>
                  <div
                    className="carperDropdown__link"
                    onClick={() => {
                      navigate(path);
                      toggleDropdown();
                    }}
                  >
                    <Icon name={icon} />
                    <span className="carperDropdown__name">{label}</span>
                  </div>
                </div>
              </AuthGuard>
            ) : (
              <div className="carperDropdown__item" key={`${label}-${index}`}>
                <div
                  className="carperDropdown__link"
                  onClick={() => {
                    navigate(path);
                    toggleDropdown();
                  }}
                >
                  <Icon name={icon} />
                  <span className="carperDropdown__name">{label}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuickAdd;
