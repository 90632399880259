// ** Custom Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** CSS **
// import "./style/input.css";

// ** Types **
import { TextInputProps } from "components/FormField/types/formField.types";

const InputField = <TFormValues extends Record<string, unknown>>(
  fieldProps: TextInputProps<TFormValues>
) => {
  const {
    id,
    errors,
    register,
    name = "",
    icon = "",
    value = "",
    label = "",
    pattern = "",
    type = "text",
    iconClass = "",
    className = "",
    maxLength = 100,
    labelClass = "label__carpet",
    placeholder = "",
    required = false,
    disabled = false,
    readOnly = false,
    defaultValue = "",
    inputMode = "text",
    maskInputType = "",
    wrapperClass = "",
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    isIconRight = false,
    errorClass,
    onIconClick,
    onChange = () => ({}),
    onBlur = () => ({}),
    onFocus = () => ({}),
    onKeyDown = () => ({}),
  } = fieldProps;

  const renderInput = () => {
    return (
      <input
        id={id}
        type={type}
        // autoComplete="off"
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        inputMode={inputMode}
        maxLength={maxLength}
        {...(name && { name })}
        placeholder={placeholder}
        {...(value && { value })}
        className={`input__carpet ${className}`}
        {...(pattern.length && { pattern })}
        {...(defaultValue && { defaultValue })}
        {...(maskInputType.length && { maskInputType })}
        {...(register && name && register(name, { onChange, onBlur }))}
      />
    );
  };

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      {label && (
        <Label
          id={id}
          label={label}
          required={required}
          labelClass={labelClass}
        />
      )}
      <div
        className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
          isIconRight ? "icon__right" : ""
        } ${wrapperClass}`}
      >
        {renderInput()}
        {label && (
          <Label
            id={id}
            label={label}
            required={required}
            labelClass={labelClass}
          />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && (
          <Icon
            name={icon}
            className={
              `${iconClass} ${
                typeof onIconClick === "function" ? "cursor-pointer" : ""
              }` || ""
            }
            {...(onIconClick && { onClick: onIconClick })}
          />
        )}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`${errorClass}`}
        />
      )}
    </div>
  );
};

export default InputField;
