import {
  addMilliseconds,
  differenceInMilliseconds,
  format,
  isToday,
  parseISO,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const convertDateToUTCISOString = (
  date: Date,
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
): string => {
  const newDate = zonedTimeToUtc(date, timezone);
  return newDate.toISOString();
};

export const dateToMilliseconds = (props: {
  startDate?: Date;
  endDate: Date;
}) => {
  const { startDate = new Date(), endDate } = props;
  return differenceInMilliseconds(endDate, startDate);
};

export const getFormattedDate = (date: string | undefined) => {
  if (date) {
    const tempDate = new Date(date);
    // if date passed is same date as today
    if (isToday(tempDate)) {
      return format(tempDate, "hh:mm a");
    }
    // if date passed is from current year
    if (new Date().getFullYear() === new Date(tempDate).getFullYear()) {
      return format(tempDate, "d LLL");
    }
    // if date is after the current year
    return format(tempDate, "LL/dd/yy");
  }
  return "";
};

export const getFormattedScheduleDate = (date: string | undefined) => {
  if (date) {
    const tempDate = new Date(date);
    // if date passed is same date as today
    if (isToday(tempDate)) {
      return format(tempDate, "hh:mm a");
    }
    // if date is after the current year
    return format(tempDate, "MMM dd, yyyy KK:mm a");
  }
  return "";
};

export const getMillisecondsToDate = (milliseconds: number) => {
  return addMilliseconds(new Date(), milliseconds);
};

export const getRoundedDate = () => {
  const minutes = new Date().getMinutes();
  if (minutes > 30) {
    return new Date().setMinutes(60);
  }
  return new Date().setMinutes(30);
};

export const convertToUTC = (dateString: any) => {
  return utcToZonedTime(parseISO(dateString), "UTC");
};
