// ** Packages **
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect, {
  InputActionMeta,
  MultiValue,
  SingleValue,
} from "react-select";

// ** CSS **
// import "../style/select.css";

// ** Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";
import Spinner from "components/Loaders/Spinner";

// ** Type **
import {
  Option,
  ReactSelectPropsTypes,
} from "components/FormField/types/formField.types";

const AsyncSelectField = <TFormValues extends Record<string, unknown>>(
  props: ReactSelectPropsTypes<TFormValues>
) => {
  const {
    name,
    label,
    value,
    errors,
    control,
    icon,
    getOptions, // EX. ()=>{{label:"",value:""}}
    getOnChange,
    defaultOptions, // EX. {label:"",value:""}
    iconClass = "",
    errorClass = "",
    isMulti = false,
    OptionComponent,
    labelClass = "",
    required = false,
    noOptionsMessage,
    placeholder = "",
    disabled = false,
    wrapperClass = "",
    isLoading = false,
    defaultSelectValue,
    isIconRight = false,
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    singleValueComponent,
    menuPlacement = "auto",
    onFocusApiCall = true,
    menuPosition = "fixed",
    serveSideSearch = false,
    onChange: onCustomChange,
    MultiValueComponent,
    notClearable = false,
    isPhoneCountry = false,
    showSpinner = false,
  } = props;
  const selectRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState<Option[]>(defaultOptions ?? []);
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    if (selectRef?.current) {
      const inputField = selectRef?.current?.querySelector("input");
      if (inputField) {
        inputField.autocomplete = "new-password";
      }
    }
  }, [selectRef?.current]);

  const fetchOption = async ({ pageNo = page, optionValue = search } = {}) => {
    setLoading(true);
    const data = await getOptions?.({
      search: optionValue,
      page: pageNo,
      name,
    });
    if (data) {
      setTotal(data.count || 0);
      if (pageNo !== 1) {
        const tempOptions = [...options];
        data.option.forEach((op) => {
          if (!tempOptions.find((el) => el.value === op.value)) {
            tempOptions.push(op);
          }
        });
        setOptions(tempOptions);
      } else {
        setOptions(data.option);
      }
      setPage(pageNo + 1);
    } else {
      setPage(1);
      setSearch("");
      setTotal(0);
    }
    setLoading(false);
    return data;
  };

  const onMenuScrollToBottom = (e: any) => {
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
      total > options.length &&
      getOptions
    ) {
      fetchOption();
    }
  };

  const onInputChange = debounce(
    (e: string, inputMetaData: InputActionMeta) => {
      // this condition for when user blur or close that time no need to api call
      if (inputMetaData.action !== "menu-close" && getOptions) {
        setSearch(e);
        fetchOption({ optionValue: e, pageNo: 1 });
      }
    },
    200
  );

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      <div
        className={`selectCarpet ${wrapperClass} ${
          showSpinner && "loaderActive pointer-events-none"
        }`}
      >
        <div
          className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
            isIconRight ? "icon__right" : ""
          }`}
          ref={selectRef}
        >
          {control && name ? (
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, value: innerValue, ref } }) => {
                const controllerValue = innerValue as
                  | MultiValue<Option>
                  | SingleValue<Option>;
                return (
                  <>
                    <ReactSelect
                      key={name}
                      ref={ref}
                      options={options}
                      openMenuOnFocus
                      isDisabled={disabled}
                      isLoading={isLoading || (loading && !showSpinner)}
                      className="basic-single"
                      placeholder={placeholder}
                      isClearable={!notClearable}
                      menuPosition={menuPosition}
                      classNamePrefix="selectCarpet"
                      {...(isMulti && { isMulti })}
                      menuPlacement={menuPlacement}
                      onMenuScrollToBottom={onMenuScrollToBottom}
                      {...(noOptionsMessage && { noOptionsMessage })}
                      {...(isMulti && { defaultValue: defaultOptions })}
                      onInputChange={
                        serveSideSearch ? onInputChange : undefined
                      }
                      value={controllerValue}
                      defaultValue={defaultSelectValue}
                      {...(onFocusApiCall && {
                        onFocus: () => getOptions && fetchOption({ pageNo: 1 }),
                      })}
                      onChange={(selectedOption, actionData) => {
                        if (
                          isPhoneCountry &&
                          actionData?.action === "select-option"
                        ) {
                          const updatedOptions = options.filter((val) => {
                            return selectedOption?.some(
                              (item: { label: string }) =>
                                item.label.split(",")[2] ===
                                val.label.split(",")[2]
                            );
                          });

                          onChange(updatedOptions);
                          getOnChange?.(updatedOptions);
                          onCustomChange?.(updatedOptions);
                        } else {
                          onChange(selectedOption);
                          getOnChange?.(selectedOption);
                          onCustomChange?.(selectedOption);
                        }
                      }}
                      components={{
                        ...(OptionComponent && { Option: OptionComponent }),
                        ...(singleValueComponent && {
                          SingleValue: singleValueComponent,
                        }),
                        ...(MultiValueComponent && {
                          MultiValueLabel: MultiValueComponent,
                        }),
                      }}
                    />
                    {showSpinner && (
                      <Spinner smallLoaderClassName="!absolute !top-[12px] !right-[36px] !p-[5px]" />
                    )}
                  </>
                );
              }}
            />
          ) : (
            <>
              <ReactSelect
                value={value}
                // openMenuOnFocus
                // ref={ref}
                options={options}
                isLoading={isLoading || (loading && !showSpinner)}
                isDisabled={disabled}
                className="basic-single"
                isClearable={!notClearable}
                placeholder={placeholder}
                menuPosition={menuPosition}
                classNamePrefix="selectCarpet"
                {...(isMulti && { isMulti })}
                menuPlacement={menuPlacement}
                defaultValue={defaultSelectValue}
                onMenuScrollToBottom={onMenuScrollToBottom}
                {...(noOptionsMessage && { noOptionsMessage })}
                {...(isMulti && { defaultValue: defaultOptions })}
                onInputChange={serveSideSearch ? onInputChange : undefined}
                {...(onFocusApiCall && {
                  onFocus: () => fetchOption({ pageNo: 1 }),
                })}
                onChange={(selectedOption) => {
                  getOnChange?.(selectedOption);
                  onCustomChange?.(selectedOption);
                }}
                components={{
                  ...(OptionComponent && { Option: OptionComponent }),
                  ...(singleValueComponent && {
                    SingleValue: singleValueComponent,
                  }),
                }}
              />
              {showSpinner && (
                <Spinner smallLoaderClassName="!absolute !top-[12px] !right-[36px] !p-[5px]" />
              )}
            </>
          )}
          {label && (
            <Label
              id={name}
              label={label}
              labelClass={labelClass}
              required={required}
            />
          )}
          <span className={`bgWrapper ${fieldBGClassName}`} />
          {icon && <Icon className={iconClass} name={icon} />}
        </div>
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default AsyncSelectField;
